.assessment-list {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $light-gray-7;
    text-align: left;
    border-radius: 20px;
    padding: 14px 18px 13px 18px;
    width: 285px;
    min-height: 92px;
    font-family: 'Graphik';
    margin-bottom: 20px;
    border-width: 2px;

    &.selected {
      background: $white;
      border-color: $purple;
    }

    &:hover,
    &:focus {
      border-color: $purple;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-content {
      font-size: 14px;
      span {
        margin-right: 5px;
      }
    }

    &-tag {
      display: flex;
      align-items: center;
      height: 25px;
      border-radius: 8px;
      padding: 2px 8px;
      white-space: nowrap;
      letter-spacing: -0.05em;
      font-size: 14px;
    }

    &-new {
      color: $purple;
      background: $light-purple;
    }

    &-due {
      color: black;
      background: $light-gray;
    }

    &-overdue {
      color: $red;
      background: $light-red;
    }

    &-completed {
      color: $green;
      background: $light-green;
    }
  }

  &__loading {
    height: 86px;
    min-width: 249px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  &__spinner {
    width: 305px;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }

  &__main-container {
    position: relative;
    margin-right: 15px;
    width: 305px;
    max-height: 250px;
    margin-bottom: 70px;
    min-height: 30px;

    &::-webkit-scrollbar {
      width: 10;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 10px;

      &:hover {
        background: $purple-2;
      }
    }

    &:after {
      content: '';
      // box-shadow: 0 -6px 26px 17px $light-purple;
      width: 100%;
      position: absolute;
      display: block;
      bottom: -4px;
    }
  }

  &-container {
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      display: block;
    }
  }

  &-header {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.05em;
    color: $dark-gray;
    width: 100%;
  }
}
