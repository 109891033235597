.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 30px;

  &__error {
    display: block;
    width: 100%;
    color: $danger;
    font-size: 14px;
    font-weight: 400;
  }

  &__assess {
    width: 100%;
    margin-top: 35px;

    &-name {
      font-size: 14px;
      display: flex;
      align-self: baseline;
      margin-top: 5px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    width: 100%;

    .category {
      color: $purple;
      font-size: 16px;
    }

    span {
      margin-right: 5px;
    }
  }

  .radio-level-container {
    margin-bottom: 25px;
  }

  .field-wrapper {
    margin-bottom: 0;
  }

  .text-area-container,
  textarea {
    max-width: none;
  }

  textarea {
    height: 65px;
  }

  & > .field-wrapper {
    margin-top: 27px;
  }
}
