.mfa {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 100px;
  width: 957px;

  p,
  h5,
  h3 {
    margin-bottom: 25px;
  }

  h3 {
    font-size: 36px;
  }

  p {
    text-align: center;
  }

  &__actions {
    width: 420px;

    input {
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;

      &:first-child {
        margin-bottom: 50px;
      }
    }
  }
}

.dialog-mfa {
  .modal__footer {
    justify-content: flex-end;
  }
}
