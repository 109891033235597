.member-add {
  &__list {
    position: absolute;
    background-color: white;
    border: solid;
    border-width: 0.1px;
    border-color: $light-gray;
    min-width: 100%;
    border-radius: 10px;
    cursor: none;
    z-index: 1;
    overflow-y: auto;
    max-height: 140px;
  }
  &__list-option {
    white-space: nowrap;
    min-height: 35px;
    &.hover,
    &:hover {
      background-color: $light-purple;
    }
  }
  &__input {
    position: relative;

    &-wrapper {
      display: flex;
      justify-content: space-between;

      #full_name {
        // width: 45%;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0%;
      }

      #email {
        // width: 50%;
        // margin-left: 50px;
      }
      #email::placeholder {
        font-size: 13px;
      }
    }

    input {
      width: 100%;
      max-height: 35px;
      padding: 9px 9px 9px 13px;
      border-radius: 8px;
      border: solid 1px $light-gray;

      &:focus-visible {
        border: solid 1px $light-gray;
      }

      &.error {
        border: solid 1px $danger;

        & + .error-icon {
          display: block;
          top: 10px;
          right: 6px;
        }
      }
    }

    &-text,
    &-error {
      bottom: -20px;
    }

    &-text {
      margin: 5px 0 0 2px;
    }

    &-error {
      line-height: 17px;
      color: $danger;
    }
  }

  input,
  .select {
    font-size: 16px;
    color: $dark-gray;

    &:disabled {
      border: solid 1px $light-gray;
      background: $light-gray;
    }
  }

  .select {
    & + .error-icon + div {
      position: absolute;
      bottom: -20px;
    }
    .dropdown-content {
      top: 29px;
      width: 100%;
    }
  }

  &__button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: $purple;
    width: 100%;
    text-align: end;
  }

  .align-baseline {
    &__error {
      color: $danger;
      margin-top: 0;
      padding-top: 0;
    }
    &__role-select {
      width: 25%;
    }
  }
}
