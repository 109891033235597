.results-text {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  &.bold {
    font-weight: bold;
  }
}

.slider-list {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
  &.quotes {
    padding: 0;
    li {
      list-style-type: none;
      padding-bottom: 10px;
    }
  }
}

#level-slider-skills {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  height: 65px;
  user-select: none;
  width: 400px;
  margin: 0;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    width: calc(100% * 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $dark-gray;
  }
  input,
  label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
  }
  label {
    color: $purple;
    position: relative;
    &::before {
      content: attr(data-level-label);
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, 25px);
      font-weight: 600;
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 13px;
      border: 1px solid $purple;
      background: $white;
      border-radius: 50%;
      pointer-events: none;
      user-select: none;
      z-index: 1;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }
  }
  input {
    display: none;
    &:checked {
      + label::after {
        background: $purple;
      }
    }
    &:not(:checked) {
      + label::before {
        color: $purple-inactive;
      }
      + label::after {
        border-color: $purple-inactive;
      }
    }
  }
}
.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .header {
    margin-right: 10px;
    text-align: right;
  }
  .completed,
  .in-progress {
    margin-left: 0;
    text-align: center;
    border-radius: 8px;
    width: 100px;
    height: 24px;
    padding-top: 4px;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;
  }
  .completed {
    background-color: $light-green-2;
    color: $green-2;
  }
  .in-progress {
    background-color: $light-orange;
    color: $orange;
  }
}

.historical-results {
  &.dropdown {
    max-width: 350px;
    height: 35px;
  }
  .dropdown {
    &-content {
      width: 100%;
      max-height: 400px;
      top: 29px;
      border: 1px solid $light-gray;
    }
    &-item {
      &:not(:disabled) {
        padding-left: 30px;
      }
    }
  }
}

.recharts-legend-item-text-selected {
  font-weight: 600 !important;
}
