$border-radius-large: 20px;
$border-radius-medium: 12px;

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin shadow {
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
}

#chat-portal {
  display: flex;
  width: 100%;
}

.chat-box {
  @include shadow;
  background-color: $white;
  width: 100%;
  margin: 25px;
  padding: 10px;
  border-radius: $border-radius-large;
  animation: chatbox-appear 1s ease forwards;
}
.chat-box.with-sidenav {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 6px 6px 8px -6px rgba(0, 0, 0, 0.1);
}
.chat-box.start-mode,
.chat-box.end-mode,
.chat-box.normal-mode {
  width: 667px;
  height: 572px;
  &.ventureverse {
    position: relative;
    min-width: 90%;
    width: 90%;
    height: calc((100vh - 200px) / 2);
    transform: translateY(38%);
    margin: 0 auto;
  }
}
.chat-box.max-mode {
  margin: 0;
  height: calc(100vh - 220px);
}
.chat-box.init-mode,
.chat-box.min-mode {
  width: 59px;
  height: 0;
  padding: 0;
}
.chat-box-heading {
  @include rainbowBackground;
  display: flex;
  padding: 15px;
  margin: -10px -10px 6px -10px;
  height: 51px;
  border-top-right-radius: $border-radius-large;
  border-top-left-radius: $border-radius-large;
  color: $white;
  .side-nav-max-icon {
    position: absolute;
    left: 25px;
    cursor: pointer;
    .with-sidenav {
      transition: margin ease-in 0.4s;
      margin-left: -60px;
      fill: rgba(92, 92, 92, 0.6);
    }
  }
  .to-left {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    align-items: center;
    width: 120%;
    height: 120%;
    margin-top: -5px;
    svg {
      margin-right: 10px;
      color: $white;
      &.small {
        width: 25.84px;
        height: 23.11px;
        margin-top: -2px;
      }
    }
    span {
      display: flex;
      text-wrap: nowrap;
      white-space: nowrap;
    }
    .header-notification {
      display: flex;
      border-radius: 50%;
      width: 19px;
      height: 19px;
      background-color: $white;
      color: $purple-5;
      margin-left: 10px;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }
  .to-right {
    @include flex-center;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 0;
    height: 51px;
    svg {
      width: 1.8rem;
    }
    .info {
      display: flex;
      color: $purple-5;
      justify-content: center;
    }
  }
}
.chat-box-heading.with-sidenav {
  border-top-left-radius: 0;
}
.chat-box-heading.min-mode {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 100%;
  border-radius: $border-radius-large;
  cursor: pointer;
  @include shadow;
}
.chat-box-heading.init-mode {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 65px;
  height: 59px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  z-index: 0;
  border-radius: 50%;
  .to-left {
    svg {
      margin-right: 0;
      height: 100%;
    }
  }
  &:before {
    content: '';
    background: linear-gradient(
      90deg,
      $red,
      $orange,
      $yellow,
      $green,
      $cyan,
      $blue,
      $purple,
      $pink,
      $red
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    opacity: 0;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;

    -webkit-box-shadow: 0 2px 10px 0 rgba(142, 57, 255, 0.29);
    box-shadow: 0 2px 10px 0 rgba(142, 57, 255, 0.29);
  }
  &:hover:before {
    opacity: 1;
  }
  &:after {
    @include rainbowBackground;

    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
}
.chat-box-heading.init-mode.warm-up {
  &:before {
    opacity: 1;
  }
}
.scroll-container {
  position: relative;
  height: 91%;
  display: flex;
  flex-direction: column;
  &.max-mode {
    height: 93%;
  }
  &.ventureverse {
    height: 100%;
  }
  .outer {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 5px;
  }
  .inner {
    position: relative;
  }
  .heading-sm {
    text-align: center;
    font-size: 0.8em;
  }
  & input {
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 42px;
    font-size: 14px;
    width: 100%;
  }
  .footer {
    position: relative;
    margin: 0 8px 8px 8px;
  }
  .new-message {
    @include shadow;
    font-size: 0.75rem;
    background-color: #7500c0;
    position: absolute;
    color: white;
    left: 50%;
    bottom: 22px;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: $border-radius-large;
    width: 173px;
    letter-spacing: 0.001rem;
    span {
      display: flex;
      align-items: center;
      margin-left: 18%;
      svg {
        margin-left: 10px;
        animation: bouncing 1s linear infinite;
        animation-direction: alternate-reverse;
      }
    }
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes chatbox-appear {
  from {
    bottom: -20px;
    opacity: 0.5;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
