.inline-edit-container {
  display: flex;
  align-items: center;
  input {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    border: 1px solid rgba(92, 92, 92, 0.6);
    padding: 8px;
    margin-right: 5px;
    &:focus {
      border: 1px solid #555;
    }
  }
}
