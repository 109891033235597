header {
    height: 59px;
    border-left: 1px solid $light-gray;
}

.header-icons {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  button {
    padding: 0;
    span {
      display: flex;
      align-items: center;
      gap: 11px;
    }
    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.img__user-profile {
    width: 32px;
    height: 32px;

    &:hover {
        cursor: pointer;
    }
}

.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s;
  height: 2em;
  width: 2em;
  padding: 0.25em;
  border-radius: 50%;
}

.icon-wrap:hover {
  background-color: $light-gray;
  cursor: pointer;
}