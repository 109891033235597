.delete-role-modal {
  height: 450px;

  .role-container {
    margin-top: 20px;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.8px;

    .role-heading {
      color: $black;
      text-align: right;
      display: flex;
      width: 142px;
      height: 30px;
      flex-direction: column;
      flex-shrink: 0;
    }
    .role-content {
      color: $purple;
      width: 327px;
      height: 30px;
      flex-shrink: 0;
    }
  }

  .modal__footer {
    margin-top: 50px;
  }
}
