.change-password {
  display: flex;
  width: 61.6vw;
  max-width: 957px;
  justify-content: center;

  &__error {
    color: $danger;
  }

  &.container {
    padding: 36px 40px;
  }

  form {
    width: 420px;

    .field-wrapper {
      &:nth-child(2) {
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    button {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 32px;
    }
  }
}
