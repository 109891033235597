@import './editAssessment.scss';

.team-item {
  display: flex;
  flex-direction: column;
  margin: 23px 0;
  min-height: 70px;
  justify-content: center;

  &__divider {
    border-bottom: 1px solid $light-gray;
    margin-top: 20px;
  }

  &.container {
    padding: 15px 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    a.item-arrow:hover {
      background: none !important;
    }

    h3 {
      display: flex;
      align-items: center;
      color: black;
      font-size: 24px;
      font-weight: 600;
      word-break: break-all;

      span {
        margin: 0 10px;
        color: $dark-gray;
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    &-actions {
      display: flex;
      align-items: center;

      a {
        width: auto;
        padding: 0 10px;
        white-space: nowrap;
      }
    }
  }

  &__edit:not(.hide) {
    .team-item__header {
      justify-content: flex-end;
    }

    form > .field-wrapper {position: absolute;
      top: -75px;
      width: 68%;
    }
  }

  &__expand {
    .item-arrow {
      transform: rotate(-45deg);
    }
  }

  &__show {
    height: auto;

    .team-item__content {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    display: none;
    padding-top: 15px;

    .team-members.container {
      background: none;
      width: 100%;
      max-width: none;
      padding: 0;
    }

    .create-team {
      width: auto;
      max-width: none;
      padding: 0;

      & > .field-wrapper .control-content {
        position: absolute;
        width: 68%;
        top: -67px;
      }
    }

    .create-team__info {
      margin-top: 0;
    }

    .create-team__actions {
      position: static;
    }

    .create-team__actions {
      justify-content: flex-start;
    }
  }

  &__card {
    display: grid;
    grid-template-columns: 60% 35% 5%;
    grid-gap: 5%;
    width: 100%;
  }

  &__card-2 {
    p {
      text-align: end;
      grid-template-columns: 40% 60%;
      grid-gap: 2.5rem;
      margin-bottom: 0.5rem;
    }
    span.value {
      color: black;
    }
  }

  button:disabled {
    color: $light-gray;
  }
}
