.assessment-header {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 20px;
  margin-bottom: 23px;

  &__info {
    display: flex;
    margin-bottom: 23px;
  }

  &__title {
    font-size: 14px;
    color: $purple;
    margin-bottom: 5px;
  }

  &__name {
    font-size: 24px;
    color: black;
  }

  &__date {
    span {
      margin-right: 5px;
    }
  }
}
