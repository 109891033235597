.team-members {
  position: relative;

  h3 {
    color: black;
  }

  form {
    margin-top: 30px;
  }

  &__options {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -70px;
    width: 700px;
    gap: 10px;
    justify-content: flex-end;
  }
}
