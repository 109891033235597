.side-menu {
  background-color: $white;

  .brand-container {
    // padding: 0 0 40px 22px;
    // border-bottom: 1px solid #cecece;
    height: 59px;
    .logo {
      max-height: 59px;
      max-width: 202px;
    }
    span {
      font-weight: bold;
      font-size: 22px;
    }
  }

  hr {
   margin: 0 1.5rem; 
   &.pre-separator {
    margin-top: 2rem;
  }
  }
  
  .menu {
    position: relative;
    .menu__item {
      padding: 13px 0 13px 22px;
      text-decoration: none;
      color: $dark-gray;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;

      &:hover {
        background-color: $light-purple;
        color: $purple;
      }
    }

    .active-item {
      color: $purple;
    }

    a:last-child {
      // position: absolute;
      // bottom: 0;
      // width: 100%;
    }
  }

  .profile-nav {
    padding: 18px 21px;
    bottom: 50px;
    border: 1px solid #cecece;

    .profile-nav__menu {
      bottom: 50px;
    }
  }
}
