.dashboard-container {
  display: flex;
  flex-direction: column;
}

.report-bug-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $purple;
  a {
    text-decoration: none;
    letter-spacing: -0.48px;
  }
  svg {
    margin-left: 4px;
  }
}
