.bot-suggestion {
  display: flex;
  position: relative;
  margin: 2px 10px;
  .title {
    position: absolute;
    top: -14px;
    margin-left: 2px;
    font-size: 0.7rem;
    color: $dark-gray;
    font-weight: 700;
  }
  ul {
    li {
      position: relative;
      display: block;
      color: $purple;
      border: 1px solid;
      cursor: pointer;
      border-radius: 8px;
      margin: 8px 8px 8px 2px;
      padding: 4px 12px;
      white-space: nowrap;
      animation: fadeIn 0.6s linear;
      animation-fill-mode: both;
      &:hover {
        background-color: rgba(161, 0, 255, 0.16);
      }
    }
    li:nth-child(2) {
      animation-delay: 0.25s;
    }
    li:nth-child(3) {
      animation-delay: 0.5s;
    }
  }
}
.bot-suggestion.no-animation {
  ul {
    li {
      animation: none;
    }
  }
}

// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 15px;
  }
  75% {
    opacity: 0.5;
    top: 0px;
  }
  100% {
    opacity: 1;
  }
}
