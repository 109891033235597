/* src/assets/styles/utils/_mixins.scss  */

@function toRem($px) {
  $rem: calc($px / 16px);
  @return #{$rem}rem;
}

@mixin px2rem($prop, $px) {
  @if type-of($px) == 'number' {
    #{$prop}: toRem($px);
  } @else {
    $rem_values: ();
    @each $value in $px {
      @if $value == 0 or type-of($value) != 'number' {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, (toRem($value)));
      }
    }
    #{$prop}: $rem_values;
  }
}

@mixin rainbowFont {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.05em;

  /* Gradient */

  background: linear-gradient(
    63.06deg,
    #ff50a0 0.01%,
    #a100ff 42.74%,
    #7500c0 79.12%,
    #00ffff 110.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin rainbowBackground {
  background: rgb(255, 80, 160) no-repeat;
  background: linear-gradient(
      60deg,
      rgba(255, 80, 160, 1) 0%,
      rgba(161, 0, 255, 1) 45%,
      rgba(117, 0, 192, 1) 85%,
      rgba(0, 255, 255, 1) 100%
    )
    no-repeat;
}

@mixin _color_transparency($color, $transparency, $property: color) {
  #{$property}: rgba($color, $transparency);
}