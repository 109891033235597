.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: 1px solid $purple;
  border-radius: 100%;

  &__active {
    &:after {
      content: '';
      height: 13px;
      width: 13px;
      background: $purple;
      border-radius: 100%;
    }
  }
}
