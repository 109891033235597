.text-area {
  font-weight: 300;
  font-size: 16px;

  &-container {
    position: relative;
    max-width: 433px;

    & + .error-icon {
      display: none !important;
    }
  }

  & + .error-icon {
    top: 10px;
    right: 10px;
  }

  &-sm {
    width: 100%;
    max-width: 341px;
    height: 126px;
  }

  &-md {
    width: 100%;
    max-width: 433px;
    height: 153px;
  }

  &-lg {
    width: 100%;
    max-width: 100%;
  }
}
