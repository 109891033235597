.container-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 957px;
  margin-bottom: 40px;

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin-top: 52px;
    margin-bottom: 32px;

    h1 {
      display: flex;
      align-items: center;
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.05em;

      /* Gradient */

      background: linear-gradient(
        63.06deg,
        #ff50a0 0.01%,
        #a100ff 42.74%,
        #7500c0 79.12%,
        #00ffff 110.88%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      .back-arrow {
        position: absolute;
        left: -45px;
      }
    }
    .right-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
    }
  }

  &-button-label {
    font-family: Graphik;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.05em;
    color: $purple;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 20px;
    width: 100%;
  }

  .add-new-user-layout {
    position: relative;
    display: flex;
    flex-direction: column;

    .buttons-wrapper {
      display: flex;
      position: absolute;
      right: 0;
      top: auto;

      .add-another-user-button {
        border: none;
      }
    }
  }
}

.builder__container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
