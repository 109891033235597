.card {
  flex-shrink: 0;
  background: $white;
  border-radius: 24px;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s;
  .outer-header {
    position: absolute;
    top: -40px;
    left: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    color: $dark-gray;
  }
  .inner-header {
    font-weight: 300;
    font-size: 1.5rem;
    background: var(
      --gradient,
      linear-gradient(45deg, $pink 0%, $purple 38.54%, $purple-4 71.35%, $cyan 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .card-image {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    img {
      display: block;
      object-fit: fill;
      height: 270px;
      -webkit-transition: -webkit-transform 0.8s;
      transition: transform 0.8s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .card-icon {
    display: flex;
    flex-direction: row;
    svg {
      margin-top: 10px;
    }
  }
  .content {
    margin: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1;
    flex: 1;
  }
  .card-action {
    div {
      display: flex;
    }
    button {
      box-shadow: none !important;
      margin: 7px;
      font-size: 0.875rem;
    }
  }
  &.with-outer-header {
    margin-top: 40px;
  }
  &.with-card-image {
    padding: 0;
    .card-action {
      padding: 10px 20px 15px;
    }
    .content {
      padding: 20px 20px 5px;
    }
  }
  &.with-card-icon {
    .card-action {
      padding: 5px 45px 0;
    }
  }
  &:hover {
    transform: translateY(-2px);
    box-shadow: 4px 4px 25px -10px rgba(66, 68, 90, 1);
  }
}
