.message {
  margin-top: 10px;
  margin-left: 50px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  display: flow-root;
  .time-stamp {
    font-size: 0.7rem;
    font-style: italic;
    margin-left: 10px;
    margin-top: 10px;
    color: $dark-gray;
  }
  .wrapper {
    display: flex;
    margin-left: -40px;
    svg {
      width: 24px;
      height: 21.46px;
    }
    .icon {
      height: 35px;
      width: 35px;
      padding: 5px;
      background-color: $pink;
      border-radius: 50%;
      margin-top: 3px;
      color: $white;
    }
    .mentor {
      padding: 12px;
      background-color: $light-gray-9;
      border: none;
      border-radius: $border-radius-medium;
      display: flex;
      flex-direction: column;
      margin: 3px 12px 6px;
      max-width: 77%;
      min-height: 28px;
      align-items: center;
      white-space: pre-line;
      hr {
        border: 0.5px solid $light-gray;
        width: 70%;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      .typing-indicator {
        ul {
          display: flex;
        }
        ul li {
          width: 12px;
          height: 12px;
          list-style: none;
          margin: auto 4px;
          border-radius: 50%;
          background-color: $dark-gray;
          animation: bouncing 1s linear infinite;
          animation-direction: alternate-reverse;
        }
        ul li:nth-child(2) {
          animation-delay: 0.2s;
        }
        ul li:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }
  .user {
    padding: 12px;
    background-color: $light-purple;
    border-radius: $border-radius-medium;
    margin: 3px 12px 6px;
    float: right;
    max-width: 77%;
    white-space: pre-line;
  }
  .reaction {
    display: flex;
    width: 100%;
    margin-left: 12px;
    span {
      display: flex;
      align-items: center;
      color: rgba(92, 92, 92, 0.4);
      margin-right: 10px;
      font-size: 0.563rem;
      font-weight: 500;
      cursor: pointer;
      svg {
        width: 19px;
        margin-right: 4px;
      }
      &.pencil {
        position: absolute;
        right: 10px;
      }
    }
    .thumbs-up:hover,
    .thumbs-down:hover {
      fill: $purple;
    }
  }
  .message-suggestions {
    white-space: pre-line;
  }
}
.to-right {
  text-align: right;
}

@keyframes bouncing {
  0% {
    transform: translateY(4px);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  }
  75%,
  100% {
    transform: translateY(-2px);
  }
}
