.question {
  position: relative;
  background: $white;
  margin-top: 25px;
  border: 2px solid transparent;

  &__hover {
    border-radius: 20px;
    border: 2px solid $purple;
    border-bottom: 2px solid $purple !important;
  }

  &:last-child {
    border-bottom: none;
  }

  &__field {
    &-category {
      max-width: none;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    &-desc {
      max-width: none;
    }
  }

  &__actions {
    position: absolute;
    right: -94px;
    top: 0;
    color: $red;
  }

  &__drag-icon {
    position: absolute;
    width: 20px;
    top: 5px;
    left: -35px;
    height: 41px;
    color: $light-gray;
    display: FLEX;
    flex-direction: column;
    justify-content: space-between;
  }
}
