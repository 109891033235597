.tooltip-container {
  display: inline-block;
  position: relative;
  .text-top-left {
    @apply shadow-md;

    visibility: hidden;
    position: absolute;
    background-color: #fff;
    min-width: 219px;
    padding: 10px 18px;
    color: #000;
    font-size: 0.75rem;
    text-align: left;
    line-height: normal;
    border-radius: 10px;
    border: 1.3px solid $light-gray-3;
    z-index: 10;
    top: -7px;
    right: calc(100% + 10px);
    opacity: 0;
    &::after {
      content: '';
      position: absolute;
      top: 16px;
      right: -5px;
      margin-top: -5px;
      width: 9px;
      height: 9px;
      background-color: #fff;
      rotate: 45deg;
      border: 1.3px solid $light-gray-3;
      border-left: none;
      border-bottom: none;
    }
  }
  &:hover .text-top-left {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.7s;
  }
}
