.create-admin {
  .name-field {
    width: 90%;
  }

  .client-wrapper {
    width: 100%;

    .name {
      margin-top: 20px;
    }
  }
  .permissions-wrapper {
    width: 100%;

    .permissions {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.admin-added {
  text-align: center;
  position: relative;
  @include px2rem(min-height, 490px);

  .admin-added__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
    color: $purple;
  }

  .admin-added__info {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: $dark-gray;
    @include px2rem(margin-bottom, 33px);
    @include px2rem(padding, 0 57px);
  }

  .admin-added__btn {
    display: inline-block;
    @include px2rem(min-width, 420px);
  }

  &::after {
    content: url('./../../../assets/letter.svg');
    position: absolute;
    left: 30%;
    @include px2rem(top, 233px);
  }
}
