.dropdown {
  position: relative;
  height: min-content;

  &-content {
    display: none;
    right: 0;
    margin-top: 5px;
    position: absolute;
    background-color: $white;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px $background-modal;
    z-index: 1;
    border-radius: 8px;
    padding: 7px;
  }

  &-item {
    padding: 10px 15px 10px 15px;
    display: flex;
    width: 100%;
    &-span {
      line-height: 19px;
      white-space: nowrap;
    }
    &-img {
      width: 19px;
      height: 19px;
      margin-right: 1rem;
      display: initial;
    }
    &:hover,
    &.selected {
      background: $light-purple;
      color: $purple;
      font-weight: 500;
      svg,
      path,
      circle {
        stroke: $purple !important;
      }
    }
    &:disabled {
      cursor: default;
      font-weight: 500;
      color: $dark-gray;
      &:hover {
        background-color: $white;
      }
    }
  }

  &-show {
    display: block;
  }
  &-selected-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.dropdown-item-img,
.dropdown-item-span {
  flex-shrink: 0;
}

.dropdown-item-span {
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.dropdown-item-icon-end {
  margin-left: auto;
  flex-shrink: 0;
}

.dropdown-item-icon-end svg {
  fill: blue;
  stroke: blue;
}

.dropdown-item-icon-danger svg {
  fill: red !important;
  stroke: red !important;
}

.dropdown-item-space {
  margin-right: 10px !important;
}

.dropdown-item-padding-with-icon {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}

.dropdown-item-bottom-circle-plus {
  margin-left: -3px;
  color: #a100ff;
  font-size: 18px;
  font-weight: 500;
  padding-left: 11px;
  .dropdown-item-span {
    margin-top: 6px !important;
  }

}


