.assessment {
  &-lists {
    width: 957px;

    h3 {
      margin-bottom: 35px;
      color: $purple;
    }

    h4 {
      margin-bottom: 15px;
    }

    p {
      color: black;
      font-size: 18px;
      display: flex;
      align-items: center;

      span {
        font-weight: normal;
        color: $dark-gray;
        font-size: 16px;
        margin-left: 15px;
      }
    }

    ul {
      margin-bottom: 25px;

      .baseline-item {
        justify-content: start;

        .label-state {
          margin-left: 15px;
        }
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        padding: 0 35px;
        margin-bottom: 25px;
      }
    }
  }
}
