.create-team {
  position: relative;

  &__name {
    width: 100%;
    max-width: none;
    margin-left: 0 !important;
  }

  > form > .field-wrapper {
    margin-bottom: 0;

    .horizontal_field .control-content {
      width: 100%;
      max-width: none;
      margin-left: 0 !important;
    }

    input {
      padding-right: 30px;
      color: #000000;
    }
  }
  .client-field-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: 'Graphik';
    font-style: normal;
    line-height: 17px;
    margin-bottom: 35px;

    .client-heading {
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      letter-spacing: -0.05em;
      color: #000000;
      margin-right: 30px;
    }

    .client-name-field {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      margin-right: 50px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;

    .team-description {
      width: 35%;
    }
    > .field-wrapper {
      width: 30%;
      margin-right: 10px;
    }
    .date-heading {
      font-weight: 600;
      font-size: 14px;
    }
    .calendar {
      width: 20%;
      margin-left: 35px;
    }
    .calendar-icon {
      color: $purple;
    }
    .selected-date {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.05em;
    }
  }

  &__options {
    width: 30%;
    // display: flex;
    // flex-direction: column;
    // align-items: start;

    .field-wrapper label.horizontal_field {
      font-size: 14px;
      justify-content: end;
      margin-bottom: 15px;

      .control-content {
        max-width: 230px;
      }
    }
  }

  &__actions {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -70px;
    width: 700px;
    gap: 10px;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;

      &:disabled {
        span {
          color: $white;
        }
      }
    }
  }
}
