@import './topBanner.scss';
@import '../components/requestDemo/demo.scss';

body {
  @include rainbowBackground;
}

.auth {
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 44px;

    .auth-title {
      color: $purple;
      font-family: Graphik;
      font-size: 33px;
      font-weight: 600;
      line-height: 43px;
      letter-spacing: -0.05em;
      text-align: center;
    }

    p {
      @include font-face(inherit, 16px, 300, 16px);
      margin-top: 10px;
      text-align: center;
      min-width: 345px;
    }
  }

  &-image {
    margin-bottom: 25px;
  }

  &-request-demo {
    color: $white !important;

    a {
      color: $white;
    }
  }

  &-footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      @include font-face($dark-gray, 14px, 400, 14px);
      letter-spacing: -0.03em;

      button {
        color: $black;
      }

      a {
        color: $white;
      }

      button,
      a {
        text-decoration: none;
        font-weight: 800;
        margin-left: 5px;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    // Override amplify styles - start
    div[data-amplify-router] {
      background: none;
      box-shadow: none;
      border-color: none;
      border-width: none;
      border-style: none;
    }

    [data-amplify-authenticator] [data-amplify-form] {
      padding: 0;
    }

    div[data-amplify-container] {
      width: 100%;
    }

    .amplify {
      &-tabs {
        display: none;
      }

      &-field__error-message {
        color: $red;
      }

      &-checkbox__icon {
        background: $purple;
      }

      &-label {
        @include font-face($black, 14px, 600, 15px);
      }

      &-heading {
        color: $purple;
        font-family: Graphik;
        font-size: 33px;
        font-weight: 600;
        line-height: 43px;
        letter-spacing: -0.05em;
        text-align: center;
      }

      &-field {
        margin-bottom: 15px;
      }

      &-field-group {
        display: flex;
        align-items: start;
        border-radius: 12px;
      }

      &-selectfield {
        margin-bottom: 0 !important;
      }

      &-select {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.03em;
      }

      &-input,
      &-select {
        border: 1px solid $light-gray;
        border-radius: 12px;
        height: 35px;
        font-weight: 300;

        &::placeholder {
          @include font-face(inherit, 16px, 300, 19px);
        }
      }

      &-button[type='submit'] {
        background: $purple;
        height: 41px;
        border-radius: 36px;
        margin-top: 5px;
        color: $white;
        font-weight: 600 !important;
        box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.45);

        &:hover {
          background: $purple-2;
        }
      }

      &-button[data-variation='link'] {
        display: none;
      }

      &-field__show-password {
        height: 35px;
        border: 1px solid $light-gray;
      }
    }
    // Override amplify styles - end
  }

  &-form-container {
    position: relative;
    max-width: 473px;
    width: 100%;
    background: $white;
    padding: 40px 65px 50px 65px;
    border-radius: 66px;
    box-shadow: 0px 0px 21px -1px rgba(0, 0, 0, 0.47);
  }

  &-sign-in {
    .auth-title {
      font-family: Graphik;
      font-size: 40px;
      font-weight: 300;
      line-height: 48px;
      letter-spacing: -0.05em;
      text-align: center;
      background-clip: text !important;
      -webkit-background-clip: text !important;
      background: -webkit-linear-gradient(
        45deg,
        rgba(255, 80, 160, 1),
        rgba(161, 0, 255, 1),
        rgba(117, 0, 192, 1),
        rgba(0, 255, 255, 1)
      );
      -webkit-text-fill-color: transparent;
    }

    .auth-request-demo {
      position: absolute;
      bottom: -45px;
    }
  }

  &-reset-password {
    .auth-header {
      margin-bottom: 15px;
    }
    .auth-footer {
      margin-top: 10px;
    }
    .auth-form-container {
      max-width: 650px;
      padding: 40px 100px 50px 100px;
    }
  }

  &-confirm-sign-in {
    .auth-header {
      .auth-phone-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
        width: 420px;

        button {
          margin-top: 27px;
        }
      }

      .auth-phone-label {
        @include font-face($dark-gray, 16px, 600, 19px);
        margin: 0;
      }

      .auth-phone-number {
        @include font-face($dark-gray, 36px, 600, 43px);
        text-align: center;
        letter-spacing: -0.05em;
      }
    }
    .auth-form-container {
      max-width: 766px;
      padding-bottom: 45px;

      input {
        margin-bottom: 10px;
      }

      button[type='button'] {
        background: $purple;
        height: 41px;
        border-radius: 36px;
      }

      .amplify-field-group__field-wrapper,
      button[type='submit'] {
        width: 420px;
        margin: 0 auto;
      }

      .amplify-field-group__field-wrapper {
        &:before {
          content: 'Confirm Authentication Code';
          display: block;
          position: relative;
          top: -5px;
          @include font-face($dark-gray, 14px, 600, 17px);
          letter-spacing: -0.05em;
        }
      }
    }
  }

  &-force-new-password {
    margin-bottom: 100px;

    .display-none {
      display: none !important;
    }

    .force-new-password-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      h3 {
        line-height: 37px !important;
        margin-bottom: 10px;
        @include rainbowFont;
      }
    }

    .amplify-heading {
      display: none;
    }

    .amplify-field {
      margin-bottom: 37px;
    }

    .auth-already-account {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      font-size: 14px;

      button {
        margin-left: 5px;
        font-weight: bold;
        color: $black;
      }
    }

    .password-disclaimer {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.03em;
      margin-bottom: 25px;
    }
    .phone-number-field {
      margin-bottom: 5px;
      min-width: 100%;
      padding-inline-end: 0px;
      .amplify-button-send {
        font-size: 12px;
        padding-top: 8px;
        padding-bottom: 7px;
        width: 100%;
        color: rebeccapurple;
      }
      .amplify-button[disabled] {
        color: var(--amplify-components-button-disabled-color) !important;
      }
      .amplify-field-group__control {
        .amplify-input {
          width: 80%;
        }
      }
      .amplify-field-group__outer-end {
        width: 36%;
      }

      .amplify-field-group__field-wrapper {
        width: 53% !important;
        padding-inline-end: 0px;
        min-width: 40%;
      }

      .amplify-field-group__outer-start {
        width: 25%;
        .amplify-select {
          min-width: 5.4rem;
        }
      }
    }
    .sms-send-button {
      border-left: 0px !important;
    }

    .phone-number-disclaimer {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.03em;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .sms-code-sent-text {
      color: $purple;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.03em;
      margin-bottom: 15px;
      .sms-bold-notice {
        font-weight: bolder;
      }
    }

    .password-field {
      margin-bottom: 7px;
    }

    .auth-force-terms {
      &-container {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include rainbowBackground;
        justify-content: center;
        overflow: scroll;
        z-index: 9;
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .back-arrow {
          border-bottom: 2px solid $white;
          border-left: 2px solid $white;
        }
      }
    }

    .auth-checkbox-terms {
      @include font-face($black, 14px, 400, 17px);
      letter-spacing: -0.03em;

      button {
        text-decoration: underline;
        font-weight: 600;
        color: $black;
        margin: 0 3px;
      }
    }

    .amplify-button[type='submit'] {
      display: none;
    }

    button.button-base {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      &[disabled] {
        color: $dark-gray;
      }
    }
  }
}

.amplify-field-no-margin {
  margin-bottom: 0px !important;
}

.amplify-button-send {
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 5px;
}