//Colors
$dark-gray: #5c5c5c;
$dark-gray-40: #5c5c5c40;
$light-gray: #cecece;
$light-gray-1: rgb(206 206 206);
$light-gray-2: #949494ba;
$light-gray-3: #b0b0b0;
$light-gray-4: #c1c1c1;
$light-gray-5: #dedede;
$light-gray-6: #aeaeae;
$light-gray-7: #ffffffb3;
$light-gray-8: rgba(92, 92, 92, 0.8);
$light-gray-9: #e6e5e5;

$green: #00a786;
$green-2: #05a815;
$light-green: #00a78633;
$light-green-2: #eafee3;

$white: #ffff;
$white-light-tr: rgba(255, 255, 255, 0.97);

$yellow: #f9c543;

$orange: #fc682d;
$light-orange: rgba(252, 104, 45, 0.3);

$red: #c80404;
$red-1: #cc2222;
$red-3: #c22;
$light-red: #cc22224d;
$light-red-2: #ff00004f;
$light-red-3: rgba(204, 34, 34, 0.3);
$danger: #cc2222;
$background-modal: rgba(0, 0, 0, 0.2);

$pink: #ff50a0;

$black: #000000;

$blue: #002bff;

$cyan: #00ffd5;

$purple: #a100ff;
$purple-tr-15: rgba(161, 0, 255, 0.15);
$purple-tr-95: rgba(161, 0, 255, 0.95);
$purple-2: #840ec4;
$purple-3: #6936f5;
$purple-4: #7500c0;
$purple-5: #9501ee;
$purple-inactive: #db9cff;
$purple-inactive-2: #e7beff;
$light-purple: rgba(161, 0, 255, 0.16);
$light-purple-label: #e7beff;
$bg-gray: #f2f2f2;

$growth-os-black: #0c1f28;
$growth-os-blue-gradient: linear-gradient(180deg, #6936f5 6.41%, #369ff5 100%);
$growth-os-purple-gradient: linear-gradient(
  85.76deg,
  rgba(90, 51, 210, 0.13) 0%,
  rgba(62, 144, 246, 0.13) 94.15%
);

$box-button-shadow: 0px 0px 10px -1px #00000073;
