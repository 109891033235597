.assessment-draft {
  background: $light-gray-7;
  border-radius: 20px;
  border-width: 2px;
  display: flex !important;
  flex-direction: column;
  font-family: 'Graphik';
  height: 110px;
  justify-content: space-between;
  padding: 14px 18px 13px 18px;
  opacity: 0.5;
  text-align: left;

  &__main-container {
    position: relative;
    width: 285px;
    min-width: 285px;
    max-height: 100vh;
    overflow-y: auto;
    padding: 2rem 1rem 1.5rem 1rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: $purple;
      border-radius: 10px;

      &:hover {
        background: $purple-2;
      }
    }

    .animate-spin {
      text-align: center;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    overflow-y: scroll;

    button {
      display: block;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.05em;
    overflow: auto;
  }

  &-sub {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.05em;
  }

  &.selected {
    background: $white;
    border-color: $purple;
    opacity: 1;
  }

  &:hover,
  &:focus {
    border-color: $purple;
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
  }

  &-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &-header {
    top: -25px;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.05em;
    color: $dark-gray;
  }
}