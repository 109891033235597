.client-details {
  position: relative;

  .button-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 20px 32px;
  }

  .organization {
    width: 304px;
    height: 20px;
    flex-shrink: 0;
    margin-bottom: 8px;
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
  }
  .client-name {
    font-size: 24px;
  }
  .input-icon-container {
    position: relative;
    display: flex;
  }
  .input-icon {
    position: absolute;
    right: 135px;
    top: 50%;
    transform: translateY(-50%);
  }
  .description-container {
    width: 100%;
  }
  .client-description {
    width: 70%;
    margin-bottom: 50px;
    margin-right: 50px;
    margin-top: 20px;
  }
  .editmode {
    padding: 10px;
  }

  .textarea {
    border-radius: 12px;
    border: solid 1.3px $light-gray;
    height: 200px;
  }
  .select-field {
    width: 200px;
  }
  .client-industry {
    color: $purple;
  }

  .industry-heading {
    width: 100%;
  }

  .client-name-field {
    width: 85%;
    border-bottom: 1px solid $light-gray;
    padding-bottom: 3px;
  }
}
.dashboard-spinner {
  width: auto;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-bug-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $purple;
  position: absolute;
  right: 21%;
  top: 8%;

  .report-bug {
    color: $purple;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    text-decoration: none;
  }
  svg {
    margin-left: 5px;
  }
}
