.update-user {
  display: flex;
  position: relative;

  .email {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 1rem;
    margin-bottom: 30px;

    .value {
      color: $purple;
      font-size: 24px;
      font-weight: 600;
      line-height: 26px;
      word-break: break-word;
    }
  }

  .user-photo {
    background: $light-purple;
    border: 1px solid $light-gray;
    height: 150px;
    width: 150px;
    min-width: 150px;
    border-radius: 100%;
    margin-right: 50px;
  }

  form {
    flex-grow: 1;

    .control-content {
      max-width: 496px;
    }

    .field-wrapper:nth-child(-n + 3) {
      margin-bottom: 30px;
    }
  }

  .last-login p {
    display: grid;
    grid-template-columns: 25% 35% 45%;
    grid-gap: 1rem;
    width: 100%;
    font-weight: 600;
    color: $black;
    letter-spacing: -0.05em;
    white-space: nowrap;

    span {
      max-width: 496px;
      width: 100%;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
    }

    .password {
      color: $purple;
      font-size: 32px;
      font-weight: 600;
      line-height: 26px;
      word-break: break-word;
    }
    .reset-password {
      color: $red;
    }
  }

  &-actions {
    display: flex;
    position: absolute;
    bottom: -60px;
    right: 0;
    gap: 1rem;
  }
}

.add-team {
  position: absolute !important;
  right: 20px;
}

.remove-team {
  @extend .add-team;
  background-color: $red !important;
}
