.template-card {
  height: 244px;
  max-width: 194px;
  border-radius: 20px;
  border-width: 2px;
  border-color: white;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;

  &:hover,
  &:focus {
    border-color: $purple;
  }

  &-header-text {
    font-family: Graphik;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.05em;
    color: $purple;
  }

  &-title {
    font-family: Graphik;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.05em;
  }

  &-description {
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
    align-self: flex-start;
  }
}
