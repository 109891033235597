.candy-bar-button {
    img {
        transition: transform .2s;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.candy-box-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 6% 5%;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33%;
        font-size: 12.5px;
        font-weight: 400;
        font-family: $graphik;
        color: $growth-os-black;
        padding: 3% 0;
        text-decoration: none;
        img {
            transition: transform .2s;
        }
        &:hover {
            text-decoration: underline;
            img {
                transform: scale(1.2);
            }
        }
        &.item-selected {
            color: $purple-3;
            font-weight: 500;
            border-radius: 3px;
            background: linear-gradient(86deg, rgba(90, 51, 210, 0.20) 0%, rgba(62, 144, 246, 0.20) 94.15%);
        }
        &.item-disabled {
            pointer-events: none;
            color: $light-gray-2;
            img {
                filter: grayscale(85%);
            }
        }
    }
}