@import '../questionnaire/styles/questionnaire.scss';
@import '../assessment/styles/assessment.scss';

.take {
  position: relative;
  display: flex;
  height: 100%;
  overflow-y: auto;

  &__lists {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: $purple;
    text-align: center;
    margin: 25px 0 35px 0;
    max-width: 600px;
  }

  &__desc {
    font-size: 24px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }

  .container:not(.assessment) {
    min-height: 600px;
  }
}

.published {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10rem;
    padding-left: 10rem;
  }

  &-title {
    font-family: Graphik;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.05em;
    color: $purple !important;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &-content {
    font-family: Graphik;
    font-size: 16px !important;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-align: center;
    color: $dark-gray;
    margin-bottom: 5px;
  }

  &-button {
    margin-top: 25px;
    margin-bottom: 25px;
    text-decoration: initial !important;
    width: 50%;
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    line-height: 1.2rem !important;
  }

  &-image {
    height: 403px;
    width: 503px;
    left: 618px;
    top: 377px;
    border-radius: 0px;
    display: block;
  }
}