// General style used on the website
* {
  letter-spacing: -0.03em;
}

.hide {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: $black;
}

body {
  font-family: $graphik;
  background: $light-purple;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  @include px2rem(font-size, 36px);
  @include px2rem(line-height, 40px);
}

h2 {
  @include px2rem(font-size, 32px);
  @include px2rem(line-height, 35px);
}

h3 {
  @include px2rem(font-size, 24px);
  @include px2rem(line-height, 26px);
}

h4 {
  @include px2rem(font-size, 18px);
  @include px2rem(line-height, 20px);
}

h5 {
  @include px2rem(font-size, 16px);
  @include px2rem(line-height, 18px);
}

h6 {
  @include px2rem(font-size, 14px);
  @include px2rem(line-height, 15px);
}

h3.light {
  @include px2rem(line-height, 24px);
  font-weight: 300;
}

.body-1 {
  font-weight: 400;
  @include px2rem(font-size, 18px);
  @include px2rem(line-height, 20px);
}

.body-2 {
  font-weight: 500;
  @include px2rem(font-size, 18px);
  @include px2rem(line-height, 18px);
}
.body-3 {
  font-weight: 400;
  @include px2rem(font-size, 16px);
  @include px2rem(line-height, 18px);
}
.body-3-italic {
  font-weight: 600;
  font-style: italic;
}

.body-3-light {
  font-weight: 300;
  @include px2rem(font-size, 16px);
  @include px2rem(line-height, 16px);
}

.body-4 {
  font-weight: 400;
  @include px2rem(font-size, 14px);
  @include px2rem(line-height, 15px);
}

.bold {
  font-weight: 600 !important;
}

a {
  text-decoration-line: underline;
  color: $purple;

  &:visited {
    color: $purple-2;
  }
}

.bg-white {
  background-color: $white;
}

.bg-purple {
  background-color: $light-purple;
}

.main-container {
  background-color: $bg-gray;
}

.container {
  background: $white;
  border-radius: 20px;
  padding: 20px 30px;
}

.icon {
  &-trash {
    background: url('../../assets/trash.png') no-repeat;
    width: 25px;
    height: 28px;
    display: block;
  }
}

.error-icon {
  display: none;
  background: url('../../assets/danger.png') no-repeat;
  height: 16px;
  width: 16px;
  position: absolute;
}

.danger {
  background: $danger;
  color: $white;

  &:hover {
    background: $red;
  }
}

.modal-value {
  color: black;
}

.item-arrow {
  content: '';
  width: 15px !important;
  height: 15px !important;
  display: block !important;
  border: none !important;
  border-bottom: solid 4px $purple !important;
  border-left: solid 4px $purple !important;
  transform: rotate(225deg);
  margin-left: 25px;
  padding: 5px !important;
  border-radius: 0 !important;
}

.parachute-logo-white {
  & path {
    fill: white;
  }
}
.font-style-rainbow {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.05em;

  /* Gradient */

  background: linear-gradient(
    63.06deg,
    #ff50a0 0.01%,
    #a100ff 42.74%,
    #7500c0 79.12%,
    #00ffff 110.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
