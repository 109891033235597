.section {
  display: flex;
  border-bottom: solid 1px $light-gray !important;
  margin-bottom: 20px;
  padding-bottom: 15px;
  margin-top: 3rem;
  justify-content: space-between;
  border: 2px solid transparent;
  padding: 10px;
  width: 100%;

  .text-area-container {
    max-width: none;
  }

  &__hover {
    border-radius: 20px;
    border: 2px solid $purple;
    border-bottom: solid 2px $purple !important;
  }

  &__field {
    &-name {
      margin-bottom: 10px;
    }

    &-desc {
      max-width: none;
      height: 66px;
    }
  }

  &__questions {
    position: relative;
    width: 100%;
    // max-width: 514px;
    padding-left: 45px;
    background: $white;

    .section__field-name {
      padding-right: 45px;
    }
  }

  &__actions {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__remove {
    color: $red;
  }

  &__drag-icon {
    position: absolute;
    width: 20px;
    left: 5px;
  }

  &__actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .dropdown-item:hover {
    background: $light-purple !important;
    color: black !important;
  }

  .add-button {
    background: $purple !important;
  }

  &-dropdown {
    top: 29px;
    right: -10px;
    &.no-sections {
      position: relative;
      top: 0;
      display: flex;
      justify-content: end;
      margin-bottom: 1rem;

      .dropdown-show {
        top: 40px;
        right: 15px;
      }

      .add-button {
        background: $purple !important;
      }

      .dropdown-item:hover {
        background: $light-purple !important;
        color: $purple !important;
      }
    }
  }
}
