.textarea-container {
  display: flex;
  width: 100%;
  textarea {
    display: block;
    font-size: 0.875rem;
    resize: none;
    overflow: hidden;
    min-height: 45px;
    padding: 10px;
    &.with-pre-icon {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.with-post-icon {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.with-normal-styling {
      padding-top: 5px;
    }
    &.with-y-scroll {
      overflow-y: scroll;
    }
    &.right-radius {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &.left-radius {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:focus {
      border: 1px solid $purple;
    }
  }
  .pre-icon {
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #cecece;
  }
  .post-icon {
    display: flex;
    align-items: center;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
    color: #888888;
    cursor: pointer;
  }
}
