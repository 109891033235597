.demo {
  padding: 40px 65px 50px 65px;
  width: 542px;
  height: 647px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 77px auto 0 auto;

  h3 {
    text-align: center;
    color: #0084d4;
  }

  .form-button {
    width: 100%;
    display: block;
  }
}

.confirmation {
  .confirmation-image {
    display: block;
  }
}
