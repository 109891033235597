.label-state {
  .phase {
    text-align: center;
    font-family: Graphik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 25px;
    width: 100px;
    flex-shrink: 0;
    border-radius: 8px;
    margin-left: 15px;
    display: inline-block;
  }

  &.no-margin {
    .phase {
      margin-left: 0;
    }
  }

  .phase.upcoming,
  .phase.new,
  {
    color: $purple;
    background-color: $purple-inactive-2;
  }

  .phase.in-progress,
  .phase.due-soon,
  .phase.unknown {
    color: $orange;
    background-color: $light-orange;
  }

  .phase.past-due,
  .phase.over-due,
  {
    color: $red-3;
    background-color: $light-red-3;
  }

  .phase.admin-closed,
  .phase.closed,
  .phase.completed {
    color: $green-2;
    background-color: $light-green-2;
  }
}
