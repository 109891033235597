@import './changePassword.scss';
@import './MFA.scss';

.my-profile {
  display: flex;
  width: 957px;
  position: relative;
  flex-wrap: wrap;

  .user-photo {
    background: $light-purple;
    border: 1px solid $light-gray;
    height: 150px;
    width: 150px;
    min-width: 150px;
    border-radius: 100%;
    margin: 0 50px 30px 0;
  }

  form {
    flex-grow: 1;

    .field-wrapper,
    p {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      display: flex;
      align-items: center;

      span {
        color: black;
        font-size: 24px;
        margin-left: 10px;
      }

      .my-profile__change-pass {
        color: $purple;
        font-size: 16px;
        margin-left: 30px;
        text-decoration: none;
      }
    }
  }

  .mfa-message {
    position: absolute;
    bottom: 5px;
    left: 125px;
    color: $purple;
  }

  .phone-number {
    position: relative;

    .indicator {
      margin-left: 15px;
    }

    .mfa-message {
      bottom: -20px;
      margin: 0;
      font-size: 16px;
    }
  }

  &_phone-number {
    display: flex;
    position: relative;

    .field-wrapper {
      width: auto;
    }
  }

  &-actions {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
