.steps {
  &-wrapper {
    display: grid;
    grid-gap: 10px;
    width: 100%;
  }

  &-step {
    background-color: $light-gray;
    border-radius: 5px;
    height: 10px;
    color: transparent;
  }

  &-selected {
    background-color: $purple;
  }
}
