.dashboard-inbox {
  width: 289px;
  height: 382px;
  flex-shrink: 0;
  background: $white;
  border-radius: 44px;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
  margin: 0 25px;
  padding: 20px;

  &__content-wrapper {
    margin: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name {
    color: $black;
    align-self: flex-start;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  &__heading {
    color: $purple;
    font-family: Graphik;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -1.2px;
    background: var(
      --gradient,
      linear-gradient(45deg, #ff50a0 0%, #a100ff 38.54%, #7500c0 71.35%, #0ff 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__list-container {
    width: 100%;
    height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 25px;

    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: $purple;
      border-radius: 10px;

      &:hover {
        background: $purple-2;
      }
    }
    .animate-spin {
      text-align: center;
    }

    .list-element {
      width: 100%;
      height: 40px;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px;

      .list-name {
        color: $black;
        margin-left: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.7px;
      }
      .list-due-date {
        color: $light-gray-2;
        margin-left: auto;
        margin-right: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
        text-transform: capitalize;
      }
    }
  }

  &__go-to-link {
    align-self: flex-end;
    color: $purple;
    color: var(--purple-1, #a100ff);
    text-align: right;
    font-family: Graphik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
    text-transform: capitalize;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
