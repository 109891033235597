.contact-us {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 548px;
  width: 50%;
  max-height: 85vh;
  overflow-y: auto;
  box-sizing: border-box;

  .modal__info__text {
    color: $dark-gray;
    line-height: normal;
  }

  .modal__body {
    text-align: left;
  }
  .input-field {
    border: none;
    border-bottom: 1px solid $light-gray;
    border-radius: 0;
    margin-bottom: 15px;
  }

  .select-field {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .text-area-field {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 100%;
    width: 494px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1.3px solid $light-gray;
  }
}
