.assessment-section {
  background: $light-gray-7;
  text-align: left !important;
  border-radius: 20px;
  border-width: 2px;
  font-family: $graphik;
  width: 100%;
  min-height: 153px;
  height: auto;
  position: relative;

  &-button {
    margin: 0 !important;
    justify-content: space-between;
    display: flex !important;
    flex-direction: column;
    text-decoration: none !important;
    padding: 14px 6% 13px;
    //padding: 14px 12px 13px 12px;
    height: 100%;
    width: 100%;

  }

  &-options-button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 10px !important;
    letter-spacing: 2px !important;
    text-decoration: none !important;
    color: $dark-gray !important;
    transition: trasform .2s;
    &:hover {
      transform: scale(1.2);
      color: $purple;
    }
  }

  &-options-list {
    display: block;
    position: absolute;
    top: 28px;
    right: 10px;
    border: 1.3px solid $light-gray-3;
    border-radius: 1.3px;
    background-color: $white;
    list-style-type: none;
    padding: 1% 0;
    margin: 0;
    min-width: 165px;
    z-index: 2;

    li {
      padding: 8px 12px;
      cursor: pointer;
      white-space: nowrap;
      font-weight: 400;
      background-color: $white;
      color: $dark-gray;
      button {
        color: inherit;
        width: 100%;
        text-align: left;
        font-size: 16px !important;
        font-weight: inherit;
        line-height: 16px !important;
        letter-spacing: -0.03em !important;
        text-decoration: none !important;
      }
      &.disabled {
        pointer-events: none;
        color: $light-gray-3;
      }
      &:hover {
        font-weight: 500;
        color: $purple;
        background-color: $light-purple;
      }
    }
  }

  &-content {
    width: 100%;
    height: auto;
    word-break: break-word;
    .input-field__icons {
      right: 4px;
      svg {
        transition: transform 0.5s;
        &:hover {
          transform: scale(1.1);
          path {
            stroke: $purple;
          }
        }
      }
    }
    .field-error {
      p {
        font-size: 12px;
        text-align: left;
      }
    }

    .label-state {
      display: table;
      margin-top: 10px;
    }
  }

  &-category {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.05em;
    text-align: left;
    color: $purple;
    margin-bottom: 5px;
  }

  &-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.05em;
    text-align: left;
    color: $black;
  }

  &-input {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.05em;
    text-align: left;
    padding-left: 8px;
    padding-right: 44px;
  }

  &-sub {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.05em;
    text-align: left;
    color: $dark-gray !important;
    display: block;
    word-break: break-all;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &.selected {
    background: $white;
    border-color: $purple;
  }

  &:hover,
  &:focus {
    border-color: $purple;
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
  }
}
