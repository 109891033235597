.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: $white;
    border: 2px solid $light-gray;
    border-radius: 8px;
    display: block;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: $white;
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }

    &:after {
      left: -1px;
      bottom: 5px;
      height: 2px;
      width: 67%;
    }

    &:before {
      left: -2px;
      top: 52%;
      height: 23%;
      width: 2px;
    }
  }

  &:hover input ~ .checkmark {
    border: 2px solid $light-gray-3;
  }

  & input:checked ~ .checkmark {
    border: none;
    background-color: $purple;
  }
}
