$number-of-options: 7;
.label-content {
  position: absolute;
  border-radius: 100%;
  z-index: 2;
  color: $purple;
  font-size: 10px;
}
.radio-level-container {
  display: flex;
  justify-content: center;

  #form-title {
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }

  // Level Slider style
  .level-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    height: 65px;
    user-select: none;
    width: 400px;
    margin: 0;

    &::before {
      content: ' ';
      position: absolute;
      height: 4px;
      width: 100%;
      width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $light-gray;
    }
    input,
    label {
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }
    label {
      position: relative;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 20%;
      height: 100%;
      user-select: none;
      &::before {
        content: attr(data-level-label);
        position: absolute;
        left: 50%;
        transform: translate(-50%, 25px);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        font-weight: 500;
        top: 30%;
      }
      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 11px;
        height: 11px;
        border: none;
        background: $light-gray;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      }
    }
    input {
      display: none;
      &:checked {
        + label::before {
          opacity: 1;
        }
        + label {
          .label-content {
            display: block;
            color: $white;
          }
        }
        + label::after {
          background: $purple;
          width: 16px;
          height: 16px;
        }
        + label.disabled::after {
          background: $light-gray-2;
        }
      }
    }
  }

  // When the level slider is active
  .active {
    &::before {
      background: $purple-inactive;
    }
    label {
      color: $purple;
      &::after {
        background: $purple-inactive;
      }
    }
  }

  // Extra options style

  #extra-options {
    width: 75px;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    height: 65px;
    user-select: none;

    input,
    label {
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }
    label {
      position: relative;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 20%;
      height: 100%;
      user-select: none;
      &::before {
        content: attr(data-level-label);
        position: absolute;
        left: 50%;
        transform: translate(-50%, 25px);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        font-weight: 500;
        top: 30%;
      }
      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border: 1px solid $black;
        background: $white;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      }
    }
    input {
      display: none;
      &:checked {
        + label::before {
          opacity: 1;
        }
        + label {
          .label-content {
            display: block;
            color: $white;
          }
        }
        + label::after {
          background: $purple;
          width: 16px;
          height: 16px;
        }
        + label.disabled::after {
          background: $light-gray-2;
        }
      }
    }
  }
  //when extra options is active
  #extra-options {
    input {
      color: $purple;
      display: none;
      &:checked {
        + label::before {
          color: $purple;
          opacity: 1;
        }
        + label {
          .label-content {
            display: block;
            color: $white;
          }
        }
        + label::after {
          background: $purple;
          border: none;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &:valid {
    #level-slider {
      input {
        + label::before {
          transform: translate(-50%, 30px);
          transition: all 0.15s linear;
        }
        &:checked + label::before {
          transform: translate(-50%, 30px);
          transition: all 0.15s linear;
        }
      }
    }
  }
  & + button {
    display: block;
    position: relative;
    margin: 56px auto 0;
    padding: 10px 20px;
    appearance: none;
    transition: all 0.15s ease-in-out;
    font-family: inherit;
    font-size: 24px;
    font-weight: 600;
    background: $white;
    border: 2px solid $purple;
    border-radius: 8px;
    outline: 0;
    user-select: none;
    cursor: pointer;
    &:hover {
      background: $purple;
      color: $white;
      &:active {
        transform: scale(0.9);
      }
    }
    &::before {
      display: inline-block;
      width: 0;
      opacity: 0;
      content: '\f3f4';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      margin-right: 0;
      transform: rotate(0deg);
    }
  }
  &:invalid + button {
    pointer-events: none;
    opacity: 0.25;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}

.radio-level-container {
  label {
    &.disabled {
      pointer-events: none;
    }
  }
}