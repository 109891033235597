.button {
  &-base {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    border-radius: 36px;
    height: 40px;
    white-space: nowrap;

    &.primary {
      background: $purple;
      color: $white;
      &:not([disabled]) {
        box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.45);
      }

      &:hover:not([disabled]) {
        background: $purple-2;
      }

      &.disabled,
      &[disabled] {
        background: $light-gray;
        border: none;
        cursor: default;
        color: $dark-gray;
        &:hover {
          background: $light-gray;
        }
      }
    }

    &.inverse {
      border: 2px solid $purple;
      background: none;
      color: $purple;

      &:hover:not([disabled]) {
        background: $light-purple;
      }

      &.disabled,
      &[disabled] {
        border: 2px solid $light-gray-2;
        color: $dark-gray;
        cursor: default;
        &:hover {
          background: none;
        }
      }
      svg {
        color: $purple;
      }
    }

    &.blank {
      background: none;
      border: none;
      box-shadow: unset !important;
      transition: transform 0.5s;
      &:hover {
        background: none !important;
        transform: scale(1.1);
      }
    }

    svg {
      color: $white;
    }
  }
}
