.icon-link-list-container {
  background: $white;
  border-radius: 5px;
  min-width: 166px;
  &--list {
    li {
      min-height: 32px;
      button, a {
        color: $growth-os-black;
        text-decoration: none;
        font-family: $graphik;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;

        padding: 8px 9px;
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;

        img {
          width: 17px;
          height: 17px;
        }
      }
      &:hover {
        @include _color_transparency($purple-3, 0.1, background);
        button {
          font-weight: 600;
        }
      }
    }
  }
}