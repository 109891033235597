@import './section.scss';
@import './question.scss';

.builder {
  width: 100%;

  &__preview-header {
    width: 100%;
    height: 55px;
    border-radius: 38px 38px 0px 0px;
    background: $purple-4;
    font-family: Graphik;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.05em;
    color: white;
    padding: 1rem 1rem 1rem 2rem;
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__main-container {
    position: relative;
    margin-bottom: 100px;
    background: $white;
    border-left: 1px $light-gray solid;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 8px 30px;
    height: 100vh;
  }

  svg {
    color: $light-gray-6;
  }

  &__field {
    &-assessment-name {
      margin-bottom: 20px;
    }

    &-assessment-desc {
      .text-area-container {
        max-width: 100%;
      }
    }
  }

  &__actions {
    position: absolute;
    right: 0;
    bottom: -55px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .save-draft-spinner {
      path {
        stroke: $purple;
        fill: $purple;
      }
    }
  }

  &__add-section {
    background: black !important;
    align-self: end;
    margin-bottom: 10px;
  }

  &__publish-modal {
    &-buttons {
      display: flex;
      gap: 10px;
      justify-content: end;
    }
  }

  .board {
    margin-top: 2rem;
  }

  .no-questions-error {
    color: $red;
    height: 50px;
  }
}

.full-round {
  border-radius: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;

  &.scale {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  white-space: break-spaces;
  width: 200px;

  //styleName: Body Small 14;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.03em;
  text-align: left;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  &.scale {
    z-index: 2;
    bottom: 55px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;

  &.scale {
    background: white;
    border-width: 5px;
    border-radius: 12px;
    border: 1.3px solid #b0b0b0;
    padding: 8px 14px;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: black;
    min-width: 145px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  }
}

.tooltiptext.scale:after {
  background: #ffffff;
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  margin: 0 auto;
  position: absolute;
  transform: rotate(45deg);
  bottom: -4px;
  border-right: 1.3px solid #b0b0b0;
  border-bottom: 1.3px solid #b0b0b0;
  left: 0;
  right: 0;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 110%;
  right: 0;
  border: 1.3px solid $light-gray-3;
  border-radius: 1.3px;
  background-color: $white;
  list-style-type: none;
  padding: 6% 0;
  margin: 0;
  min-width: 165px;
  border-radius: 8px;
}

.dropdown-menu.above {
  top: auto;
  bottom: 110%;
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-menu {
  li {
    font-weight: 400;
    background-color: $white;

    button {
      width: 100%;
      text-align: left;
    }

    &.disabled {
      pointer-events: none;
      color: $light-gray-3;
    }

    &:hover {
      font-weight: 500;
      color: $purple;
      background-color: $light-purple;
    }
  }
}
