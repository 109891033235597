label {
  &.hide {
    display: none !important;
  }
  &.disabled {
    .checkmark {
      background: $light-gray !important;
    }
  }
}

.member-list__content {
  .tooltip {
    background-color: $white;
    border-radius: 6px;
    white-space: break-spaces;
    width: 200px;
  }

  .tooltiptext {
    width: 190px;
    height: 180px;
    border-radius: 12px;
    border: 1.3px solid #b0b0b0;
    background: #fff;
    padding: 5px 10px;

    /* Pill Drop Sahdow */
    box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
  }
}
