@import './memberList.scss';

.settings__ {
  &header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    height: 47px;

    > p {
      color: black;
      font-weight: 600;
      font-size: 24px;
      margin-right: 20px;
    }

    .more-button {
      margin-left: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.4px;
    }

    .mark-complete-button {
      width: 174px;
      height: 47px;
      flex-shrink: 0;
      margin-left: 5px;

      border-radius: 8px;
      border: 1.3px solid $light-gray-3;
      background: $white;
      font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.48px;
    }
    .mark-complete-button:hover {
      background: $light-purple;
      color: $purple;
    }
  }

  &options,
  &calendar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    color: $dark-gray;
    .error {
      color: red;
      height: 50px;
    }

    button:not([disabled]):hover {
      color: $purple;
    }
  }

  &dropdowns {
    display: flex;
    flex-direction: column;
    align-items: start;

    .field-wrapper label.horizontal_field {
      justify-content: end;

      .control-content {
        max-width: 230px;
      }
    }

    .option-label {
      span {
        margin-left: 20px;
        color: black;
      }
    }
  }

  &members {
    margin-top: 1.5rem;
  }
}

.calendar {
  width: 110px;
  height: 78px;
  margin-bottom: 25px;
  .date-prompt {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.05em;
    text-align: left;
    color: $purple;
    &.active {
      color: $purple;
    }
  }
  .title {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.05em;
    margin-bottom: 0.5rem;
  }
}

.member-list {
  &__content {
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: $purple;
      border-radius: 10px;

      &:hover {
        background: $purple-2;
      }
    }
  }
  .error {
    color: red;
  }
}

.custom .react-calendar__tile--active {
  background: $purple;
  color: white;
  &:enabled:hover,
  &:enabled:focus {
    background: $purple-2;
  }
}
