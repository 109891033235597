@import '../builder/styles/builder.scss';
@import '../assessmentSectionDisplay/styles/assessmentSectionDisplay';
@import '../take/styles/take.scss';
@import '../resultsHub/resultsHub.scss';
@import '../take/assessment/styles/assessmentList.scss';

.assessment {
  &__main {
    &.container {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 665px;
    }

    &.container-cleared {
      @extend .container;
      background-color: transparent !important;
      padding: 0;
      width: 100%;
    }

    h1 {
      margin-bottom: 40px;
      text-align: center;

      span {
        -webkit-text-fill-color: initial;
      }
    }

    p {
      text-align: center;
      font-size: 32px;
    }

    button {
      margin-left: 7px;
      text-decoration: underline;
      color: $purple;
      font-family: $graphik;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      letter-spacing: -0.05em;
    }
  }
}

.input-form-search {
  border-radius: 52px;
  border: 1.3px solid $light-gray;
  color: $dark-gray;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
  padding-left: 44px !important;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  + .input-field__icons {
    left: 20px;
    top: 15px;
    svg {
      width: 15px !important;
      height: 19px !important;
    }
  }
}

.no-results {
  margin-top: 3.5rem;
  text-align: center;
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.05em;
    color: $black;
    margin: 1rem;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: $light-gray-8;
  }
  &.sub-content {
    margin-top: -1.5rem;
    min-height: 153px;
  }
}
