.header-menu {
  color: $dark-gray;
  z-index: 99;
  .children {
    box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 53px;
    right: 40px;
    width: 165px;
    background-color: #fff;
    border: 1.3px solid $light-gray-3;
    border-radius: 10px;
    padding: 10px 0;
    text-align: left;
    line-height: 2.5;
    .menu-item {
      width: 100%;
      padding: 9.5px 20px;
      font-size: 1rem;
      letter-spacing: -0.03em;
    }
    .menu-item:hover {
      color: $purple;
      background-color: rgba($purple, 0.16);
    }
  }
}
