.vv {
  &-separator {
    width: 100%;
    height: 1.52px;
    background-color: $dark-gray-40;
    margin: 1rem 0;
  }

  &-bg {
    border-radius: 28px;
    border: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    margin: -10px;
    &-started {
      @extend .vv-bg;
      background: url('../../../assets/ventureverse/welcome-screen-bg.png') no-repeat;
      background-size: 100% 100%;
    }
    &-how-to-play {
      @extend .vv-bg;
      background: url('../../../assets/ventureverse/how-to-play-screen-bg.png') no-repeat;
      background-size: 100% 100%;
    }
    &-game-screen {
      @extend .vv-bg;
      position: relative;
      background: url('../../../assets/ventureverse/game-screen-bg.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  &-content {
    flex-direction: column;
  }

  &-container {
    padding: 30px 40px;
    height: 65%;
    width: 60%;
    border-radius: 10.3px;
  }
  &-container-1 {
    @extend .vv-container;
    background-color: $purple-tr-95;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    container-type: size;
    z-index: 1;
    .title {
      &.heading-1 {
        color: $white;
        font-size: 7.47cqw;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: -0.05em;
        text-align: left;
      }
      &.heading-2 {
        color: $white;
        font-size: 16.35cqw;
        font-weight: 500;
        line-height: 90px;
        letter-spacing: -0.05em;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-thickness: 3.18px;
        text-underline-offset: 1.5rem;
        margin-bottom: 22px;
      }
      &.heading-3 {
        color: $white;
        font-size: 3.9cqw;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.05em;
      }
    }
  }
  &-container-2 {
    max-width: 85%;
    max-height: 83%;
    border-radius: 12.9px;
    box-shadow: 0px 0px 18.274px -1.075px black;
    padding: 30px 50px;
    background-color: $white-light-tr;
    &.how-to-play-content {
      div {
        img {
          align-self: flex-end;
        }
        .marked-text {
          img {
            align-self: flex-start;
          }
        }
      }
      .lets-play-button {
        display: flex;
        justify-content: end;
        button {
          span {
            margin-left: -2px;
          }
          svg {
            margin-left: -10px;
            margin-right: -20px;
          }
        }
      }
      .title-1 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: left;
        color: $dark-gray;
      }
      .title-2 {
        font-size: 16.25px;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: left;
        color: $dark-gray;
      }
      .text-1 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.05em;
        text-align: left;
        color: $purple;
        line-height: 30px;
      }
      .text-2 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.05em;
        text-align: left;
        color: $dark-gray;
        line-height: 22px;
      }
      .marked-text {
        display: flex;
        gap: 1rem;
        background-color: $purple-tr-15;
        border-radius: 12.19px;
        padding: 1rem;
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.03em;
          color: $dark-gray;
          text-align: left;
        }
      }
    }
  }
  &-container-3 {
    width: 100%;
    .game-board {
      display: flex;
      position: absolute;
      width: 100%;
      top: 45px;
      .info {
        position: relative;
        width: 288px;
        height: 53px;
        margin-left: 5%;
        h1 {
          color: $purple;
        }
      }
      .status {
        position: absolute;
        display: table;
        width: 360px;
        height: 86px;
        right: 5%;
        background-color: $purple-tr-95;
        padding: 15px;
        color: $white;
        font-size: 1rem;
        border-radius: 12px;
        table > tr > td {
          padding: 2px 4px;
        }
      }
    }
  }
  &-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: end;
    justify-content: end;
    div {
      text-align: center;
      margin-top: 10px;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    font-size: 3.96cqw;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.035em;
    height: 44px;
    border-radius: 36px;
    background-color: transparent;
    color: $white;
    border: 3px solid rgba(255, 255, 255, 1);
    text-align: center;
    padding: 5px 0 5px 25px;
    svg {
      margin-left: -7px;
    }
  }

  &-button-2 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.02em;
    text-align: left;
    color: $white;
    border: none;
    background-color: transparent;
    margin-top: 10px;
  }

  &-img {
    &-rocket {
      width: 163.12px;
      height: 185.86px;
    }

    &-mission {
      width: 206.21px;
      height: 206.21px;
    }
  }
}

@media (max-width: 640px) {
  .vv {
    &-container-1 {
      .title {
        &.heading-1 {
          font-size: 9px;
          line-height: 28px;
        }
        &.heading-2 {
          font-size: 20px;
          line-height: 22px;
          text-underline-offset: 0.5rem;
          text-decoration-thickness: 1.2px;
          margin-bottom: 10px;
        }
        &.heading-3 {
          font-size: 7px;
          line-height: 10px;
        }
      }
    }
    &-container-2 {
      padding: 15px 20px;
      &.how-to-play-content {
        .text-1 {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 5px;
        }
        .text-2 {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 10px;
        }
        .title-1 {
          font-size: 12px;
          margin-bottom: 5px;
        }
        .title-2 {
          font-size: 10px;
          margin-bottom: 5px;
        }
        .marked-text {
          padding: 5px;
          gap: 0.5rem;
          grid-gap: 0.5rem;
          p {
            font-size: 10px;
            line-height: 15px;
          }
          img {
            width: 22px;
          }
        }
        div img {
          width: 90px;
        }
      }
    }
    &-separator {
      margin: 0.5rem;
    }
    &-button {
      font-size: 9px;
      padding-left: 14px;
    }
    &-button-2 {
      font-size: 9px;
    }
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .vv {
    &-container-1 {
      .title {
        &.heading-2 {
          margin-bottom: 5px;
        }
      }
    }
    &-container-2 {
      padding: 15px 20px;
      &.how-to-play-content {
        .text-1 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
        }
        .text-2 {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .title-1 {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .title-2 {
          font-size: 12px;
          margin-bottom: 5px;
        }
        .marked-text {
          padding: 5px;
          gap: 0.5rem;
          grid-gap: 0.5rem;
          p {
            font-size: 12px;
          }
          img {
            width: 22px;
          }
        }
        div img {
          width: 90px;
        }
      }
    }
    &-separator {
      margin: 0.5rem;
    }
    &-button {
      font-size: 9px;
      padding-left: 15px;
    }
    &-button-2 {
      font-size: 12px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .vv {
    &-container-2 {
      &.how-to-play-content {
        .text-1 {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 10px;
        }
        .text-2 {
          font-size: 16px;
          line-height: 20px;
        }
        .marked-text {
          p {
            font-size: 12px;
          }
          img {
            width: 32px;
          }
        }
        div img {
          width: 100px;
        }
      }
    }
    &-separator {
      margin: 0.5rem;
    }
  }
}
