.leave-page {
  &__actions {
    display: flex;
    justify-content: end;

    button {
      margin-left: 15px;
    }
  }
}
