.list-item {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 15px 180px 15px 20px;
  margin-bottom: 25px;
  position: relative;

  &-deactived {
    border: 2px solid $danger;

    h3,
    .list-item-invited,
    .list-item-info-role {
      color: rgba(92, 92, 92, 0.6) !important;
    }
  }

  .checkbox {
    position: absolute;
    left: -45px;
  }

  &-photo {
    border-radius: 100%;
    background: $light-purple;
    height: 63px;
    width: 63px;
    min-width: 63px;
  }

  &-info {
    margin-left: 20px;
    &-name {
      display: flex;
      align-items: center;

      h3 {
        color: black;
        display: flex;
        align-items: center;
        letter-spacing: -0.05em;

        .list-item-pending {
          margin: 0 15px 0 0;
          color: $danger;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.05em;
        }
      }

      .list-item-invited {
        font-style: italic;
        color: black;
      }

      .list-item-email {
        margin-left: 15px;
        word-break: break-word;
      }

      p {
        color: $dark-gray;
      }
    }

    &-role {
      margin-top: 5px;
      p {
        color: $dark-gray;
      }
    }
  }

  a,
  button {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}

.delete-danger {
  border-color: $red-1;
  border-width: 2px;

  &-tag {
    border-width: 0;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.05em;
    color: $red-1;
  }

  &-name {
    color: $dark-gray !important;
  }
}
