.client-workspace-heading {
  /* Header 16px */
  margin-left: 15px;
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  /* Black */

  color: $black;
}

.client-select {
  position: relative;
  max-width: 230px !important;
  margin: 15px;
  margin-bottom: 0;
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
