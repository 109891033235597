.baseline-modal {
  &__actions {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}
