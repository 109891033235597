.slider {
  &-container {
    display: flex;
    width: 100%;
    min-width: 300px;
    max-width: 900px;
    flex-direction: column;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
