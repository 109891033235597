.widget-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  svg {
    display: flex;
    flex-direction: row;
  }
  .title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.906rem;
  }
  .item-row {
    border-bottom: 1px solid $light-gray;
    line-height: 48px;
    &.no-border {
      border-bottom: none;
    }
    .column-1 {
      font-size: 1rem;
      font-weight: 600;
      &:before {
        content: '';
        display: inline-flex;
        width: 10px;
        height: 10px;
        background-color: $purple;
        border-radius: 50%;
        margin-right: 7px;
      }
    }
    .column-2 {
      margin-left: 15px;
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(92, 92, 92, 0.6);
    }
    .column-3 {
      display: inline-flex;
      float: right;
      font-size: 0.875rem;
      font-weight: 400;
      color: $purple;
      svg {
        color: $purple;
        align-self: center;
      }
      a {
        text-decoration: none;
      }
    }
  }
}
