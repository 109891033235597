.grid-container {
  &-title {
    font-family: $graphik;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.05em;
    text-align: left !important;
    color: $growth-os-black;
  }

  &-view-more-button {
    border: none !important;
    font-family: $graphik;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 12px;
    letter-spacing: -0.05em;
    text-align: center;
    text-decoration: none !important;
  }

  &-view-less-button {
    svg {
      width: 30px;
      height: 30px;
      stroke-width: 4px;
      color: $black;
    }
  }

  &-fade {
    mask-image: linear-gradient(to bottom, black 1%, transparent 48%);
    margin-top: 1rem;
    pointer-events: none;
  }

  &-load-more-container {
    position: relative;
    button {
      position: absolute;
      right: 0;
      top: -65px;
    }
  }

  &-load-more-button {
    padding: 11px 32px 11px 32px;
    border-radius: 36px;
    border: 1.5px !important;
    background-color: $purple !important;
    color: $white !important;
    box-shadow: $box-button-shadow;
    text-decoration: none !important;

    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 15px;
    letter-spacing: -0.03em;
    text-align: center;
    &:hover {
      background-color: $purple-2 !important;
    }

  }
}
