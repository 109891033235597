.add-button {
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a100ff;

  &-label {
    border-radius: 36px !important;
    justify-content: left;
    span {
      margin-left: 24px;
    }
  }

  &:hover {
    background: #840ec4;
  }

  &-close {
    transform: rotate(45deg);
  }

  &-sm {
    width: 40px;
    height: 40px;
  }

  &-md {
    width: 50px;
    height: 50px;
  }

  &::before,
  &::after {
    content: '';
    height: 17px;
    width: 4px;
    background: #ffffff;
    position: absolute;
  }

  &::before {
    transform: rotate(90deg);
  }
}
