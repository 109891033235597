.modal {
  background-color: $white;
  display: inline-block;
  @include px2rem(padding, 35px 44px);
  box-shadow: 0px 4px 5px 0.5px rgba(0, 0, 0, 0.38);
  border-radius: 20px;
  @include px2rem(max-width, 584px);
}

.modal-container {
  .modal__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.05em;
    color: $purple;
    @include px2rem(margin-bottom, 20px);
  }

  .modal__footer {
    display: flex;
    align-items: end;
    flex-direction: column;
  }

  .modal__footer.modal-row {
    flex-direction: row;

    button {
      margin-right: 5px;
    }
  }
}

.modal__info {
  text-align: justify;
  @include px2rem(margin-bottom, 30px);

  P {
    font-weight: 400;
    @include px2rem(font-size, 16px);
    line-height: 19px;
  }
}

.publish-info {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  text-align: left;
  @include px2rem(margin-bottom, 30px);
  p {
    display: grid;
    grid-template-columns: 20% 80%;
    text-align: justify;
    width: 100%;
    padding: 10px;
  }
  .value {
    color: $purple;
  }
}

.modal__btn {
  display: flex;
  justify-content: flex-end;

  button:last-child {
    @include px2rem(margin-left, 15px);
  }
}

.back-btn {
  border: 2px solid $purple;
  background: none;
  color: $purple;
}

.modal-bg {
  background: $background-modal;
  position: fixed;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
