.field-wrapper {
  margin-bottom: 15px;
  width: 100%;

  label {
    font-size: 16px;
    white-space: nowrap;
  }

  &.error {
    input,
    textarea,
    select {
      border: 1px solid $danger;
      border-radius: 12px;
    }

    .error-icon {
      display: block;
    }
  }

  .control-content {
    width: 100%;
    position: relative;
  }

  label.horizontal_field {
    display: flex;

    .control-content {
      margin-left: 15px;
      max-width: 300px;

      &.xs {
        max-width: 496px;
      }
    }
  }

  .horizontal_field-grid {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 1rem;
  }

  .control-content {
    .field-error {
      // padding-top: 8px;
      p {
        // line-height: 17px;
        color: $danger;
      }
    }
  }

  button {
    width: 100%;
  }
}
