.side-nav-max {
  background-color: #fafafa;
  width: 266px;
  min-width: 266px;
  height: calc(100vh - 220px);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
  transition: all 0.5s ease-out;
  &.collapsed {
    width: 0;
    min-width: 0;
  }
  .chat-history {
    font-size: 0.875rem;
    color: $dark-gray;
    height: 100%;
    flex-flow: column;
    display: flex;
    .header {
      display: flex;
      text-wrap: nowrap;
      padding: 20px 30px;
      height: 51px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    ul {
      overflow-y: auto;
      flex: 1;
      margin-bottom: 5px;
      &.collapsed {
        overflow-y: hidden;
      }
      li {
        display: flex;
        margin: 1px 10px 1px 18px;
        padding: 4px 10px;
        border-radius: 12px;
        align-items: center;
        height: 41px;
        span {
          width: 100%;
          text-wrap: nowrap;
          white-space: nowrap;
          overflow: hidden;
        }
        svg {
          margin: 0 2px;
        }
        &:hover {
          background: rgba(161, 0, 255, 0.18);
          cursor: pointer;
        }
      }
      li.active {
        background: rgba(161, 0, 255, 0.18);
      }
    }
  }
  .inline-edit-container {
    display: flex;
    align-items: center;
    input {
      background: transparent;
      border-radius: 10px;
      height: 30px;
      border: 1px solid rgba(92, 92, 92, 0.6);
      padding: 8px;
      max-width: 172px;
      margin: 0 2px 0 -4px;
      &:focus {
        border: 1px solid rgba(92, 92, 92, 0.6) !important;
      }
      &.show {
        visibility: visible;
      }
      &.hide {
        border: none;
        cursor: auto;
      }
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
}
