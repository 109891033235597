.select {
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 0 15px;
  font-weight: 300;
  font-size: 16px;
  cursor: pointer;

  &-sm,
  &-md,
  &-full {
    height: 35px;
    width: 100%;
  }

  &-sm {
    max-width: 134px;
  }

  &-md {
    max-width: 283px;
  }

  &-full {
    max-width: 100%;
  }

  &:focus,
  &:hover,
  &:active {
    border: 1px solid $purple;
  }

  & + span {
    top: 10px;
    right: -25px;
  }
  &:disabled {
    cursor: default;
    border-color: $light-gray;
  }
}
