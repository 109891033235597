.section-take {
  display: none;
  flex-direction: column;

  &__active {
    display: flex;
  }

  &__info {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 24px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    color: black;
  }

  &__desc {
    font-size: 16px;
  }
}
