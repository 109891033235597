// More calendar congifuration can be found in node_modules/react-calendar/dist/Calendar.css
.calendar {
  width: 18%;
  display: flex;
  flex-wrap: wrap;
  color: black;

  .date-heading {
    font-size: 16px;
    font-weight: 600 !important;
    margin-right: 10px;
    color: #000000;
  }

  .fa-calendar {
    color: $purple;
  }

  .selected-date {
    width: 100%;
    font-size: 24px;
    color: $purple;
  }
  .react-calendar {
    position: absolute;
    z-index: 1;
    width: 350px;
    height: 335px;
    max-width: 200%;
    background: white;
    border: 1.5px solid $purple;
    border-radius: 3%;
    box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16);
    font-family: $graphik;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 15px;
  }

  .react-calendar__navigation {
    display: flex;
    height: 34px;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.5px;
    color: black;
  }
  .react-calendar__navigation button:disabled {
    background-color: white;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
    text-decoration: none !important;
    pointer-events: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: $dark-gray;
    padding: 0.5em;
  }

  .react-calendar__month-view__days__day--weekend {
    color: black;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(92, 92, 92, 0.47);
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 15px 2px;
    background: none;
    text-align: center;
    border-radius: 50%;
  }
  .react-calendar__tile:disabled {
    background-color: white;
    color: rgba(92, 92, 92, 0.47);
  }
  .react-calendar__tile--now {
    background: $yellow;
    font-weight: bold;
    border-radius: 50%;
  }

  .react-calendar__tile--active {
    background: $purple;
    font-weight: bold;
    color: white;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: white;
    border-radius: 50%;
  }
}
