.dropdown {
  position: relative;
  height: 35px;

  &-content {
    display: none;
    right: 0;
    margin-top: 5px;
    position: absolute;
    background-color: $white;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px $background-modal;
    z-index: 1;
    border-radius: 8px;
    width: fit-content;
  }

  &-item {
    padding: 10px 15px 10px 15px;
    display: flex;
    width: 100%;
    &-span {
      line-height: 19px;
      white-space: nowrap;
      max-width: 80%;
    }
    &-delete-button {
      display: flex;
      align-items: center;
      justify-content: end;
    }
    &-img {
      width: 19px;
      height: 19px;
      margin-right: 1rem;
      display: initial;
    }
    &:hover,
    &.selected {
      background: $light-purple;
      color: $purple;
      font-weight: 500;
      svg,
      path,
      circle {
        stroke: $purple !important;
      }
    }
    &:disabled {
      cursor: default;
      font-weight: 500;
      color: $dark-gray;
      &:hover {
        background-color: $white;
      }
    }
  }

  &-show {
    display: block;
  }
  &-selected-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.disabled-role {
  background: $light-gray-1;
  cursor: not-allowed !important;
}
.dropdown-content-scrollable {
  max-height: 400px;
  overflow-y: auto;
}
