@import './listItem.scss';

.team-list {
  width: 957px;

  &__divider {
    height: 1px;
    background: $light-gray;
    width: 100%;
    display: block;
    margin: 25px 0 -5px 0;
  }
}
