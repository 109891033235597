.menu-item {
  font-family: $graphik;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  &__section-title {
    .menu-item__label {
      pointer-events: none;
      p {
        font-size: 14px;
        line-height: 15px;
        color: rgba($growth-os-black, 0.7);
      }
    }
  }
  &__link {
    text-decoration: none;
    position: relative;

    &:hover {
      background: $growth-os-purple-gradient;
    }

    &:last-of-type {
      margin-top: auto;
    }

    &:visited {
      color: $dark-gray;
    }

    &.disabled {
      pointer-events: none;
      color: $light-gray-1;
    }

    @mixin side-header {
      content: '';
      display: inline-block;
      position: absolute;
      height: 100%;
      width: 3px;
      left: 0;
    }

    &.child::before {
      @include side-header();
      background: $light-gray-1;
    }

    &.active-link:not(.disabled) {
      color: $growth-os-black;
      background: $growth-os-purple-gradient;
      font-weight: 500;
      line-height: 15px;

      svg > path {
        stroke-width: 1.7;
        fill-opacity: 1;
        stroke-opacity: 1;
      }

      &.child::before {
        @include side-header();
        background: $growth-os-blue-gradient;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 15px;
    color: $dark-gray;

    &:hover {
      background: $growth-os-purple-gradient;
    }

    &-icons {
      height: 20px;

      svg {
        position: absolute;
        right: 10px;
        height: 20px;
        width: 20px;
        margin-right: 0;
      }
    }

    .icon-chevron {
      &__up {
        display: none;
      }
    }

    .main-icon {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }

    &.disabled {
      pointer-events: none;
      color: $light-gray-1;

      svg,
      p {
        color: $light-gray-1;
      }
    }
    p {
      color: $dark-gray;
    }
  }

  &__content {
    display: none;
    flex-direction: column;

    a {
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-left: 25px;
      color: $dark-gray;
    }
  }

  &__active {
    font-weight: 500;
    line-height: 15px;
    .menu-item__label {
      svg,
      p {
        color: $growth-os-black;
      }
      .icon-chevron__up path {
        fill: transparent;
      }
      svg > path {
        stroke-width: 1.7;
        stroke-opacity: 1;
        fill-opacity: 1;
      }
    }
  }

  &__expand {
    .menu-item__label {
      .icon-chevron {
        &__up {
          display: block;
        }

        &__down {
          display: none;
        }
      }
    }
    .menu-item__content {
      display: flex;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
