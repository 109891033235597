.terms {
  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 149px;
    margin-bottom: 293px;
  }

  &-container {
    position: relative;
    background: $white;
    border-radius: 20px;
    width: 65%;
    max-width: 930px;
    height: 687px;
    padding: 35px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 21px -1px rgba(0, 0, 0, 0.47);

    .back-arrow {
      position: absolute;
      align-self: flex-start;
      left: -74px;
    }

    h1 {
      color: $purple;
    }
  }

  &-content {
    border-radius: 20px;
    overflow: scroll;
    height: 100%;
    background: rgba(206, 206, 206, 0.1);
    padding: 30px 45px;
    margin-top: 25px;
    overflow-x: hidden;

    h6 {
      padding-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 19.2px;
      padding-bottom: 20px;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5c5c5cc2;
      border-radius: 10px;
    }
  }

  &-actions {
    align-self: end;
    display: flex;

    button.primary {
      margin-left: 20px;
    }
  }
}
