.edit-assessment {
  &__actions {
    display: flex;
    justify-content: end;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }
}
