.link {
  &-base {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    border-radius: 36px;
    width: 95px;
    height: 40px;
    text-decoration: none;
    text-align: center;

    &.primary {
      background: $purple;
      color: $white;

      &:hover {
        background: $purple-2;
      }

      &.disabled,
      &[disabled] {
        background: $light-gray;
        border: none;
      }
    }

    &.inverse {
      border: 2px solid $purple;
      background: none;
      color: $purple;

      &:hover {
        background: $light-purple;
      }

      &.disabled,
      &[disabled] {
        border: 2px solid $light-gray-2;
        color: $light-gray;
      }
    }
  }
}
