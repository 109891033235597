.input-field {
  width: 100%;
  height: 35px;
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 8px;
  outline: none !important;
  padding-left: 13px;
  font-weight: 400;

  &__icons {
    position: absolute;
    display: flex;
    right: 0;
    top: 10px;

    svg {
      width: 20px;
    }
  }

  &.error,
  &.bold-input.error {
    border: 1px solid $red;
  }

  &:hover,
  &.bold-input:hover {
    border: 1px solid $light-gray-3;
  }

  &:focus,
  &.bold-input:focus {
    border: 1px solid $purple;
  }

  &:disabled,
  &.bold-input.disabled {
    border: 1px solid $light-gray-4;
    background: $light-gray-5;
  }

  &.bold-input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0 0 10px 0;
    @include font-face($purple, 24px, 600, 26px);

    &::placeholder {
      @include font-face($light-gray, 24px, 600, 26px);
      letter-spacing: -0.05em;
    }

    &:hover,
    &:focus,
    &.error {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }

  & + .input-field__icons + .error-icon {
    top: 10px;
    right: 10px;
    border: 0.25px;
  }

  .input-field__icons {
    color: #aeaeae;
    border: 0.25px;
  }

  &.error + .input-field__icons {
    right: 35px;
  }
}
