/* userTable class styles */
.userTable {
  width: 850px;
  min-width: 100%;
  border-collapse: separate; /* Separate borders for cells */
  border-spacing: 0; /* Remove default cell spacing */
  overflow: hidden; /* Clip the table content to fit the rounded corners */

  color: $black;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;

  .user-name {
    color: $black;
    font-weight: 550;
    margin-bottom: 5px;
  }

  .last-login {
    font-size: 10px;
  }

  .table-button {
    border: none;
    font-family: Graphik;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
    text-transform: capitalize;
  }

  /* tableBody class styles */
  .tableBody tr:nth-child(odd) {
    background-color: $light-gray-5;
  }

  /* table header styles */
  .tableHead th {
    font-size: 14px;
    padding: 8px;
    text-align: left;
  }

  /* table cell styles */
  td {
    padding: 10px 25px;
    text-align: left;
    border: 1px solid $light-gray;
    white-space: nowrap;
  }

  /* Apply border radius to top-left corner of first cell */
  .tableBody tr:first-child td:first-child {
    border-top-left-radius: 22px;
  }

  /* Apply border radius to top-right corner of last cell */
  .tableBody tr:first-child td:last-child {
    border-top-right-radius: 22px;
    overflow: hidden;
  }
  /* Apply border radius to bottom-left corner of first cell */
  .tableBody tr:last-child td:first-child {
    border-bottom-left-radius: 22px;
  }

  /* Apply border radius to bottom-right corner of last cell */
  .tableBody tr:last-child td:last-child {
    border-bottom-right-radius: 22px;
    overflow: hidden;
  }
}
