.chat-error-container {
  color: rgba(92, 92, 92, 0.6);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 48px 48px 0;
  svg {
    margin: 10px;
  }
  .try-again {
    font-size: 0.75rem;
    &:hover {
      cursor: pointer;
    }
  }
}
