.member-list {
  &-title {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: $dark-gray !important;
  }

  &__loading {
    display: flex;
    justify-content: center;
  }
}

.team-members {
  &-table {
    width: 100%;
    th,
    td {
      padding: 15px;
    }
    margin-top: 35px;
    gap: 10px;
    td {
      .field-wrapper {
        margin-bottom: 0;
        .control-content {
          margin-left: 0 !important;
        }
      }
    }

    th {
      text-align: start;
    }
  }

  &__info {
    p {
      margin-right: 30px;
    }
  }

  &__photo {
    display: block;
    height: 40px;
    width: 40px;
    background: $light-purple;
    border: 1px solid $light-gray;
    border-radius: 100%;

    &-container {
      width: 40px;
    }
  }

  &__remove {
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;
    color: $red;
  }

  &__empty {
    justify-content: center;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
