.tab {
  &__container {
    width: 100%;
    height: 97vh;
    max-height: 100vh;
    overflow-y: auto;

    .spinner {
      min-width: 669px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__header {
    display: flex;
    border-bottom: 2px solid $light-gray;

    &-item {
      display: flex;
      height: 70px;
      align-items: center;
      padding: 0 20px;
      position: relative;
      bottom: -1px;

      span {
        margin-right: 15px;
      }

      p {
        color: $light-gray;
      }
    }
  }

  &__content {
    width: 100%;
    margin-top: 30px;

    &-item {
      display: none;
    }

    .container {
      padding: 30px 0;
    }
  }

  &__active {
    display: flex;
    flex-direction: column;

    &.tab__header-item {
      flex-direction: row;
      border-bottom: 2px solid $purple;

      p {
        color: $purple !important;
      }

      svg,
      path,
      circle {
        fill: $purple !important;
      }

      .baseline path {
        fill: $purple !important;
      }
    }
  }

  &__header-item.tab-error {
    border-bottom: 2px solid $light-red-2;

    p {
      color: $light-red-2 !important;
    }

    svg,
    path,
    circle {
      stroke: $light-red-2 !important;
    }

    .baseline path {
      fill: $light-red-2 !important;
    }
  }

  &__active.tab-error {
    border-bottom: 2px solid red;

    p {
      color: red !important;
    }

    svg,
    path,
    circle {
      stroke: red !important;
    }

    .baseline path {
      fill: red !important;
    }
  }
}