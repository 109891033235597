.create-team {
  position: relative;
  color: $black !important;

  &__name {
    width: 100%;
    max-width: none;
    margin-left: 0 !important;
  }

  > form > .field-wrapper {
    margin-bottom: 0;

    .horizontal_field .control-content {
      width: 100%;
      max-width: none;
      margin-left: 0 !important;
    }

    input {
      padding-right: 30px;
      color: $black;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    > .field-wrapper {
      width: 55.269%;
      margin-right: 10px;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 44.731%;

    .field-wrapper label.horizontal_field {
      justify-content: end;

      .control-content {
        max-width: 230px;
      }
    }
  }

  &__actions {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -70px;
    width: 700px;
    gap: 10px;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;

      &:disabled {
        span {
          color: $white;
        }
      }
    }
  }
}
